<template>
  <b-container class="bg-light page-wrapper sandbox-custom-number-input-example">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">Custom number input DEMO.</h1>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <h4 class="mt-5">Variant 1 - It is a percentage input - component which wraps CustomNumberInput</h4>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <percentage-input
            v-model="val1"
            :precision="0"
            :state="state"
          />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          Model value: {{ val1 }}
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <h4 class="mt-5">Variant 2</h4>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <custom-number-input
            v-model="val2"
            :input-class="'rounded-pill'"
            :precision="2"
            :suffix="' UAH'"
            :min="0"
            :max="100000000"
            :state="state"
          />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          Model value: {{ val2 }}
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <h4 class="mt-5">Variant 3</h4>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <custom-number-input
            v-model="val3"
            :input-class="{ 'rounded-pill': true }"
            :precision="4"
            :suffix="' kWh'"
            :min="0"
            :max="100000000"
            :state="state"
          />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          Model value: {{ val3 }}
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <h4 class="mt-5">Variant 4</h4>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <custom-number-input
            v-model="val4"
            slider
            :precision="2"
            :suffix="' my custom suffix'"
            :min="-1000"
            :max="1000"
            :step="10.5"
            :state="state"
          />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          Model value: {{ val4 }}
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <h4 class="mt-5">Variant 5</h4>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <custom-number-input
            v-model="val5"
            slider
            slider-position="right"
            :precision="2"
            :suffix="' my other custom suffix'"
            :min="-1000"
            :max="1000"
            :step="10.5"
            :state="state"
          />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          Model value: {{ val5 }}
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col class="text-center">
          <button class="my-4 mx-2 btn btn-primary" @click="rnd">Randomize values (may be out of range)</button>
          <button class="my-4 mx-2 btn btn-primary" @click="validateTrue">Validate as VALID</button>
          <button class="my-4 mx-2 btn btn-secondary" @click="validateFalse">Validate as INVALID</button>
          <button class="my-4 mx-2 btn btn-primary" @click="clearValidation">Clear validation</button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import CustomNumberInput from '@/components/common/CustomNumberInput'
import PercentageInput from '@/components/common/PercentageInput'

export default {
  name: 'CurrencyInputExample',
  components: { CustomNumberInput, PercentageInput },
  data () {
    return {
      val1: 18,
      val2: 10.5,
      val3: 50.401,
      val4: 650.08,
      val5: 300,
      state: null
    }
  },
  methods: {
    rnd () {
      for (let i = 1; i <= 5; i++) {
        this[`val${i}`] = this.doRnd()
      }
    },
    doRnd () {
      return parseFloat((Math.random() * 100).toFixed(4))
    },
    validateTrue () {
      this.state = true
    },
    validateFalse () {
      this.state = false
    },
    clearValidation () {
      this.state = null
    }
  }
}
</script>
